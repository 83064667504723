import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Wellcome from './Wellcome';
import VisionMission from './VisionMission';
import BoardMembers from './BoardMembers';

import axios from 'axios';
import config from '../../config';
import { useParams } from 'react-router-dom';

// import FaqArea from './FaqArea'
function EventPage() {
  const location = useLocation(); 
  // Scroll to the top of the page when the route changes
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top
  }, [location]);
    return(
        <div>
            <Wellcome />
            <h1>EventPage</h1>
        </div>
    )
}
export default EventPage;