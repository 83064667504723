import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Wellcome from './Wellcome';
import config from '../../config';
// import FaqArea from './FaqArea'
function NewsPage() {
  // const location = useLocation(); 
  // Scroll to the top of the page when the route changes
  // useEffect(() => {
    // window.scrollTo(0, 0); // Scroll to the top
  // }, [location]);
    return(
        <div>
            <Wellcome />
            <section className="container news-section">
              <div className="row">
                <div class="col-lg-6 mb-4">
                  <div class="card">
                    <div className='news-img'>
                      <img src={config.FILE_URL + '../uploads/news.png'} alt="" class="card-img-top "/>
                    </div>
                    <div class="card-body">
                      <h5 class="card-title">Dr. Yunus 'Tree of Peace' award conferred by  UNESCO</h5>
                      <p class="card-text">The confusion arose because Dr Yunus falsely promoted the award as a Unesco accolade The confusion arose because Dr Yunus falsely promoted the award as a Unesco accolade</p>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 mb-4">
                  <div class="row">
                      <div class="card1  d-flex">
                          <div className='news-img'>
                            <img src={config.FILE_URL + '../uploads/news.png'} alt="" class="card-img-top img-fluid "/>
                          </div>
                          <div class="card-body">
                            <h5 class="card-title">Dr. Yunus 'Tree of Peace' award conferred by  UNESCO</h5>
                            <p class="card-text">The confusion arose because Dr Yunus falsely promoted the award as a Unesco accolade The confusion arose because Dr Yunus falsely promoted the award as a Unesco accolade</p>
                          </div>
                      </div>
                      <div class="card1  d-flex">
                          <div className='news-img'>
                            <img src={config.FILE_URL + '../uploads/news.png'} alt="" class="card-img-top img-fluid "/>
                          </div>
                          <div class="card-body">
                            <h5 class="card-title">Dr. Yunus 'Tree of Peace' award conferred by  UNESCO</h5>
                            <p class="card-text">The confusion arose because Dr Yunus falsely promoted the award as a Unesco accolade The confusion arose because Dr Yunus falsely promoted the award as a Unesco accolade</p>
                          </div>
                      </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-3 col-sm-12 mb-4">
                  <div class="card2">
                    <div className='news-img'>
                      <img src={config.FILE_URL + '../uploads/news.png'} alt="" class="card-img-top "/>
                    </div>
                    <div class="card-body">
                      <h5 class="card-title">Dr. Yunus 'Tree of Peace' award conferred by  UNESCO</h5>
                      <p class="card-text">The confusion arose because Dr Yunus falsely promoted the award as a Unesco accolade The confusion arose because Dr Yunus falsely promoted the award as a Unesco accolade</p>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-3 col-sm-12 mb-4">
                  <div class="card2">
                    <div className='news-img'>
                      <img src={config.FILE_URL + '../uploads/news.png'} alt="" class="card-img-top "/>
                    </div>
                    <div class="card-body">
                      <h5 class="card-title">Dr. Yunus 'Tree of Peace' award conferred by  UNESCO</h5>
                      <p class="card-text">The confusion arose because Dr Yunus falsely promoted the award as a Unesco accolade The confusion arose because Dr Yunus falsely promoted the award as a Unesco accolade</p>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-3 col-sm-12 mb-4">
                  <div class="card2">
                    <div className='news-img'>
                      <img src={config.FILE_URL + '../uploads/news.png'} alt="" class="card-img-top "/>
                    </div>
                    <div class="card-body">
                      <h5 class="card-title">Dr. Yunus 'Tree of Peace' award conferred by  UNESCO</h5>
                      <p class="card-text">The confusion arose because Dr Yunus falsely promoted the award as a Unesco accolade The confusion arose because Dr Yunus falsely promoted the award as a Unesco accolade</p>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-3 col-sm-12 mb-4">
                  <div class="card2">
                    <div className='news-img'>
                      <img src={config.FILE_URL + '../uploads/news.png'} alt="" class="card-img-top "/>
                    </div>
                    <div class="card-body">
                      <h5 class="card-title">Dr. Yunus 'Tree of Peace' award conferred by  UNESCO</h5>
                      <p class="card-text">The confusion arose because Dr Yunus falsely promoted the award as a Unesco accolade The confusion arose because Dr Yunus falsely promoted the award as a Unesco accolade</p>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-3 col-sm-12 mb-4">
                  <div class="card2">
                    <div className='news-img'>
                      <img src={config.FILE_URL + '../uploads/news.png'} alt="" class="card-img-top "/>
                    </div>
                    <div class="card-body">
                      <h5 class="card-title">Dr. Yunus 'Tree of Peace' award conferred by  UNESCO</h5>
                      <p class="card-text">The confusion arose because Dr Yunus falsely promoted the award as a Unesco accolade The confusion arose because Dr Yunus falsely promoted the award as a Unesco accolade</p>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-3 col-sm-12 mb-4">
                  <div class="card2">
                    <div className='news-img'>
                      <img src={config.FILE_URL + '../uploads/news.png'} alt="" class="card-img-top "/>
                    </div>
                    <div class="card-body">
                      <h5 class="card-title">Dr. Yunus 'Tree of Peace' award conferred by  UNESCO</h5>
                      <p class="card-text">The confusion arose because Dr Yunus falsely promoted the award as a Unesco accolade The confusion arose because Dr Yunus falsely promoted the award as a Unesco accolade</p>
                    </div>
                  </div>
                </div>
              </div>
              <nav aria-label="Page navigation example text-center">
                <ul class="pagination">
                  <li class="page-item">
                    <a class="page-link" href="#0" aria-label="Previous">
                      <span aria-hidden="true">&laquo;</span>
                      <span class="sr-only">Previous</span>
                    </a>
                  </li>
                  <li class="page-item"><a class="page-link" href="#0">1</a></li>
                  <li class="page-item"><a class="page-link" href="#0">2</a></li>
                  <li class="page-item"><a class="page-link" href="#0">3</a></li>
                  <li class="page-item"><a class="page-link" href="#0">5</a></li>
                  <li class="page-item"><a class="page-link" href="#0">5</a></li>
                  <li class="page-item">
                    <a class="page-link" href="#0" aria-label="Next">
                      <span aria-hidden="true">&raquo;</span>
                      <span class="sr-only">Next</span>
                    </a>
                  </li>
                </ul>
              </nav>
            </section>
        </div>
    )
}
export default NewsPage;