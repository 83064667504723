import React, { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import config from '../config';
// import { SiteInfoContext } from '../SiteInfoContext';
// import TranslationComponent from './TranslationComponent';
function Header(){ 
    // const siteInfo = useContext(SiteInfoContext);
    let location = useLocation();
    return(
        <>
            <div className='top-bar '>
                <div className='container-fluid'>
                    <div className='row top-bar-area'>
                        <div className='col-md-6 col-6'>
                            <div className='top-bar-left'>
                                <div className='d-flex'>
                                    <Link to={config.BASE_URL}>
                                        {/* <img src="http://grameenmpf.com/uploads/logo-primary.png" alt="" className="img-fluid"/> */}
                                        <img src={config.FILE_URL + "logo-primary.png"} alt="" className="img-fluid" />
                                    </Link> 
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6 col-6'>
                            <div className='top-bar-right-text text-end h-50'> 
                                <a href="#0"><i class="fa-solid fa-envelope-open"> </i>info@grameenmottsho.com</a>
                                <a href="#0"><i class="fa-solid fa-phone-volume"> </i>+880 1000 000000</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <header className='header header-menu' id="header">
                <img src={config.FILE_URL + "nav-cow2.png"} alt='' className="nav-cow-img img-fluid"/>  
                <div className='container1 col-7 ssbl-bg-primary ssbl-fc-white'>  
                
                <nav className=" navbar navbar-expand-xl bg-body-tertiary mx-4 px-5">
                    <div className="container-fluid p-0">
                        <div></div>
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarNav">
                            <ul className="navbar-nav w-100 justify-content-between">
                                <li className="nav-item">
                                    <Link className={`nav-link ${location.pathname === '/' ? 'active' : ''}`} to="./">Home</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className={`nav-link ${location.pathname === '/about-us' ? 'active' : ''}`} to="./#0">About Us</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className={`nav-link ${location.pathname === '/our-programs' ? 'active' : ''}`} to="./#0">Project</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className={`nav-link ${location.pathname === '/area-of-impact' ? 'active' : ''}`} to="./#0">News</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className={`nav-link ${location.pathname === '/success-story' ? 'active' : ''}`} to="./#0">Gallery</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className={`nav-link ${location.pathname === '/success-story' ? 'active' : ''}`} to="./#0">Contact Us</Link>
                                </li>
                                {/* <li className="nav-item dropdown">
                                    <a className="nav-link dropdown-toggle" href="0" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        Media
                                    </a>
                                    <ul className="dropdown-menu">
                                        <li><Link className={`dropdown-item ${location.pathname === '/gallery' ? 'active' : ''}`} to="./gallery">Gallery</Link></li>
                                        <li><Link className={`dropdown-item ${location.pathname === '/news-page' ? 'active' : ''}`} to="./news-page">News</Link></li>
                                        <li><Link className={`dropdown-item ${location.pathname === '/events' ? 'active' : ''}`} to="./events">Events</Link></li>
                                    </ul>
                                </li> */}
                            </ul>
                        </div>
                    </div>
                    </nav>
                </div>
            </header>
        </>      
    ) 
}
export default Header;