import React, { useEffect, useState }  from 'react';
import { Swiper, SwiperSlide} from 'swiper/react';
import { Pagination } from 'swiper/modules';
// import required modules
import { Autoplay } from 'swiper/modules'
import 'swiper/css';
import axios from 'axios';
// import { Link } from 'react-router-dom'; 
import config from '../../config';
const TopBanner = () =>{
    const [sliderJsonData, setSliderJsonData] = useState([]);

    async function fetchSliderData() {
        try {
            const response = await axios.get(config.API_URL_LIVE + config.SLIDER_URL);
            if(response.status === 200){
                 setSliderJsonData(response.data.data);
            }
            else {
                console.error('Unexpected status code:', response.status);
            }
            
        } catch (error) {
            console.error('Error reading JSON file:', error);
        }
    }// siteinfo function 
    useEffect(() => {  
        fetchSliderData();
      }, []);
    return(
        <>
            <section className='slider-section'>
                <div className='slider-body'>
                    <div className='container-fluid'>
                        <div className="row slider-area">
                            <Swiper
                            spaceBetween={0}
                            slidesPerView={1}
                            speed={500}
                            autoplay={{
                            delay: 2000,
                            disableOnInteraction: false,
                            }}
                            loop={true}
                            pagination={true} 
                            modules={[Autoplay, Pagination]}
                            className="swiper mySwiper"
                            >
                                <SwiperSlide className="swiper-slide">
                                    <img src={config.FILE_URL + "slider1.webp"} alt='' className="img img-fluid w-100"/>
                                </SwiperSlide>                            
                                <SwiperSlide className="swiper-slide">
                                    <img src={config.FILE_URL + "slider1.webp"} alt='' className="img img-fluid w-100"/>
                                </SwiperSlide>                            
                            </Swiper>
                        </div>
                    </div>
                </div>
                {/* <img src='../../../uploads/banner-tag.png' alt='' className='slider-tag'/> */}
            </section>
        </>
    )
}
export default TopBanner;