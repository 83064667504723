import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Wellcome from './Wellcome';
import Principles from './Principles';
import Letters from './Letters';
import Status from './Status';


// import FaqArea from './FaqArea'
function ProgramPage() {
  const location = useLocation();

  // Scroll to the top of the page when the route changes
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top
  }, [location]);
    return(
        <div>
            <Wellcome />
            <Principles />
            <Letters />
            <Status />
        </div>
    )
}
export default ProgramPage;