import React ,{useEffect} from 'react';
import { useLocation } from 'react-router-dom';

import Slider from './Slider';
import Wellcome from './Wellcome';
import Service from './Service';
import BackgroundStory from './BackgroundStory';
import SuccessStory from './SuccessStory';
import Facts from './Facts';
import News from './News';


// import FaqArea from './FaqArea'
function HomePage() {
  const location = useLocation(); 

  // Scroll to the top of the page when the route changes
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top
  }, [location]);
    return(
        <div>
            <Slider/>
            <Wellcome />   
            <Service />
            <BackgroundStory />
            <SuccessStory />
            <Facts/>
            <News />
        </div>
    )
}
export default HomePage;