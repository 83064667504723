import React from 'react';
import config from '../../config';
// import { Link } from 'react-router-dom'; 

const Status =  ({reportData}) => {

  return (
    <section className=' nobin'>
        <div className='container'>
            <div className='row'>
                <div className="page-title text-center">
                    <p>Program<span> Status</span></p>
                </div>
                <div className='nobin-parts'>
                    <div className='row'>
                        <div className='col-md-4 text-center nobin-part'>
                          <img src={config.FILE_URL + '../uploads/glove.png'} alt="" className="img-fluid" />
                            <p>District: 13</p>
                        </div>
                        <div className='col-md-4 text-center nobin-part'>
                          <img src={config.FILE_URL + '../uploads/runing-person.png'} alt="" className="img-fluid" />
                            <p>Nobins: 54012</p>
                        </div>
                        <div className='col-md-4 text-center nobin-part'>
                          <img src={config.FILE_URL + '../uploads/dollar.png'} alt="" className="img-fluid" />
                            <p>Amount: 45784</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}

export default Status;

