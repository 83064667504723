import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Wellcome from './Wellcome';

// import FaqArea from './FaqArea'
function LearningCenter() {
  const location = useLocation(); 
  // Scroll to the top of the page when the route changes
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top
  }, [location]);
    return(
        <div>
            <Wellcome />
        </div>
    )
}
export default LearningCenter;