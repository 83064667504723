import React from 'react';
import { Link } from 'react-router-dom';
import config from '../config';
// import { SiteInfoContext } from '../SiteInfoContext';
const Footer = () =>{
    // const siteInfo = useContext(SiteInfoContext);
    return(
      <div>
        <footer className="footer">
            <div className="footer-shape m-0 p-0">
                <img src={config.FILE_URL + "footer-shape.png"} alt='' className="img"/>             
            </div>
            <div className="container-fluid">
                <div className="row mx-4 px-1">
                <div className="col-md-4 col-lg-4 d-sm-block d-none">
                        <div className="footer-about">
                            <div className="footer-logo">
                                {/* <img src="http://grameenmpf.com/uploads/logo-light.png" alt="" className="img-fluid rounded"/>  */}
                                <img src={config.FILE_URL + "logo-light.png"} alt="" className="img-fluid rounded"/> 
                            </div>
                            <div className="footer-location d-flex ">
                                <div className="me-3"><i className="fa-solid fa-location-dot"></i></div>
                                <p className='mb-1'>Telecom Bhaban (Level - 4) 53/1 Box Nagar, Zoo Road,<br/>Mirpur, Dhaka-1216</p>
                            </div>
                            <div className="footer-location d-flex">
                                <div className="me-3"><i className="fa-solid fa-phone"></i></div>
                                <p className='mb-1'>+880 01717 00000</p>                            
                            </div>
                            <div className="footer-location d-flex">
                                <div className="me-3"><i className="fa-solid fa-envelope"></i></div>
                                <p className='mb-1'>info@grameenmpf.com</p>
                            </div>
                            <div className="footer-location d-flex">
                                <div className="me-3"><i className="fa-solid fa-globe"></i></div>
                                <p className='mb-1'>https://grameenmpf.com/</p>
                            </div>
                            <div className="social-link d-flex flex-wrap ">
                                <a href="https://www.facebook.com/profile.php?id=61550082346165" target="_blank" rel="noreferrer" className="facebook"><i className="fa-brands fa-facebook-f"></i></a>
                                <a href="https://twitter.com/phicsart27" rel="noreferrer"  target="_blank" className="twitter"><i className="fa-brands fa-twitter"></i></a>
                                <a href="https://www.youtube.com/channel/UCDgpBnVbbksnr5HslMfT1cQ" rel="noreferrer" target="_blank" className="youtube"><i className="fa-brands fa-youtube"></i></a>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-8 col-lg-8'>
                        <div className='footer-link-area'>
                            <div className='row'>
                                <div className='col-md-4'>
                                    <ul className="footer-link">
                                        <li><Link to="/#0">About Us</Link></li>
                                        <li><Link to="/#0">FAQ</Link></li>
                                        <li><Link to="/#0">Terms & Condition</Link></li>
                                        <li><Link to="/#0">Privacy Policy</Link></li>
                                    </ul>
                                </div>
                                <div className='col-md-4'>
                                    <ul className="footer-link">
                                        <li><Link to="/#0">Contact Us</Link></li>
                                        <li><Link to="./#0">Career</Link></li>
                                        <li><Link to="./#0">Board Of Directors</Link></li>
                                        <li><Link to="./#0">Gallery</Link></li>
                                    </ul>
                                </div>
                                <div className='col-md-4'>
                                    <ul className="footer-link">
                                        <li><Link to="./#0">Events</Link></li>
                                        <li><Link to="./#0">Sitemap</Link></li>
                                        <li><Link to="./#0">Privacy Policy</Link></li>
                                        <li><Link to="./#0">Contact Us</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>                       
                    </div> 
                    <div className='copyright row'>
                        <div><p className=" text-center text-white pt-3 mx-5 p-0"> Copyright <i className="fa-solid fa-copyright"></i> 2023  |  All Rights Reserved</p></div>
                    </div>                       
                </div>
            </div>
        </footer>      
      </div>
    )
}
export default Footer;
