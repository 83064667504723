const config = {
  BASE_URL: process.env.REACT_APP_BASE_URL,
  CMS_URL: process.env.REACT_CMS_URL,
  API_URL_LIVE: process.env.REACT_APP_API_LIVE_URL,
  API_URL: process.env.REACT_APP_API_URL,
  FILE_URL: process.env.REACT_APP_FILE_URL, 

  
  COUNTRY_URL: 'country',
  COMPANY_URL: 'company',
  REPORTS_URL: 'report.json',
  CONCERN_URL: 'our-concern',
  TRAINING_URL: 'training-center',
  PORTFOLIO_URL: 'info-gallery',
  PERSON_URL: 'person',
  CERTIFICATION_URL: 'certification',
  NEWS_URL: 'news',
  CLIENT_URL: 'client',
  SITE_INFO_URL: 'site-info/1',
  SLIDER_URL: 'slider',
  PAGES_URL: 'page',
  RESUME_URL: 'resume',
  CONTACT_URL: 'contact',
};

export default config;
